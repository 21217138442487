import { useMediaQuery } from "react-responsive"

const sizes = { MOBILE: 430 } as const

export const useBreakpoints = (): {
  isMobile: boolean
  sizes: { readonly MOBILE: 430 }
} => {
  return {
    isMobile: useMediaQuery({ maxWidth: sizes.MOBILE }),
    sizes,
  }
}
