import { Spin } from "antd"
import React from "react"

export const LoadingPage: React.FC = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <Spin size={"large"} spinning />
    </div>
  )
}
