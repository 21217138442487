import { Tour, TourProps } from "antd"
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useLocation, useNavigate } from "react-router"

import { useMeQuery, useUpdateUserMutation } from "src/graphql"

import { useBreakpoints } from "src/hooks/useBreakpoints"

import { ReactComponent as CloseIcon } from "src/icons/close.svg"

import { useApp } from "../app"
import { StepsType, getRoleName, stepsInfo, type RefUnionType } from "./utils"

type TourContextType = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  refs: Record<RefUnionType, React.MutableRefObject<null>>
}

export const TourContext = createContext<TourContextType | undefined>(undefined)

export function useTour(): TourContextType {
  const context = useContext(TourContext)

  if (context === undefined) {
    throw new Error("useTour must be used within an TourProvider")
  }

  return context
}

export const TourProvider: FC<Partial<PropsWithChildren<TourContextType>>> = ({ children }) => {
  const { data: userData, refetch: refetchUser } = useMeQuery({ fetchPolicy: "network-only" })
  const user = userData?.me

  const {
    offline: { isOffline },
  } = useApp()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isMobile } = useBreakpoints()

  const [updateUser] = useUpdateUserMutation()

  const role = getRoleName(user?.role?.data?.attributes?.name)

  const [open, setOpen] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(0)

  const resultsRef = useRef(null)
  const salesRef = useRef(null)
  const addProductsRef = useRef(null)
  const weeklySalesRef = useRef(null)
  const closeWeeklySalesRef = useRef(null)
  const leaderboardRef = useRef(null)
  const filtersRef = useRef(null)
  const teamRef = useRef(null)
  const howItWorksRef = useRef(null)
  const verifyRef = useRef(null)
  const salonSearchRef = useRef(null)
  const reportRef = useRef(null)

  const refs: TourContextType["refs"] = {
    results: resultsRef,
    sales: salesRef,
    addProducts: addProductsRef,
    weeklySales: weeklySalesRef,
    closeWeeklySales: closeWeeklySalesRef,
    leaderboard: leaderboardRef,
    filters: filtersRef,
    team: teamRef,
    howItWorks: howItWorksRef,
    verify: verifyRef,
    salonSearch: salonSearchRef,
    report: reportRef,
  }

  const prevButtonDefault = { children: isMobile ? "<" : "Back" }

  const steps: StepsType = useMemo(
    () => ({
      salon: {
        desktop: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description:
                  "See the total products sold this week, compared to last week, and a breakdown by Full-size and Mini-size products.",
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.addProducts.title,
                description: stepsInfo.addProducts.description,
                target: () => refs.addProducts.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () =>
                    (
                      refs.addProducts.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
              },
              gap: 2,
            },
            {
              step: {
                title: stepsInfo.weeklySales.title,
                description: stepsInfo.weeklySales.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () =>
                    (
                      refs.closeWeeklySales.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.weeklySales.current,
              },
              gap: [4, 8],
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                nextButtonProps: isMobile
                  ? {
                      onClick: () => {
                        setOpen(false)
                        navigate("/account/profile/team")
                      },
                    }
                  : undefined,
                target: () => refs.leaderboard.current,
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/profile/team")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 4,
            },
          ],
          team: [
            {
              step: {
                title: stepsInfo.team.title,
                description: stepsInfo.team.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.team.current,
              },
              gap: [24, 12],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/profile/team")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 12,
            },
          ],
        },
        mobile: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: [0, 24],
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description:
                  "See the total products sold this week, compared to last week, and a breakdown by Full-size and Mini-size products.",
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.addProducts.title,
                description: stepsInfo.addProducts.description,
                target: () => refs.addProducts.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () =>
                    (
                      refs.addProducts.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
              },
              gap: 2,
            },
            {
              step: {
                title: stepsInfo.weeklySales.title,
                description: stepsInfo.weeklySales.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () =>
                    (
                      refs.closeWeeklySales.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.weeklySales.current,
              },
              gap: 4,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                target: () => refs.leaderboard.current,
              },
              gap: [48, 12],
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/profile/team")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 12,
            },
          ],
          team: [
            {
              step: {
                title: stepsInfo.team.title,
                description: stepsInfo.team.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.team.current,
              },
              gap: [48, 12],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/profile/team")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 96,
            },
          ],
        },
      },
      dsc: {
        desktop: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description: stepsInfo.sales.description,
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.addProducts.title,
                description: stepsInfo.addProducts.description,
                target: () => refs.addProducts.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () =>
                    (
                      refs.addProducts.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
              },
              gap: 2,
            },
            {
              step: {
                title: stepsInfo.weeklySales.title,
                description: (
                  <React.Fragment>
                    {"Choose the salon from the dropdown. Next, enter the quantity of sold products per each stylist."}
                    <br />
                    <span>Tip: </span>
                    {
                      "You can edit the number of products sold by returning to this window and choosing the right week and stylist."
                    }
                  </React.Fragment>
                ),
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () =>
                    (
                      refs.closeWeeklySales.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
                nextButtonProps: {
                  onClick: () =>
                    (
                      refs.closeWeeklySales.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
                target: () => refs.weeklySales.current,
              },
              gap: [4, 8],
            },
            {
              step: {
                title: stepsInfo.verify.title,
                description: stepsInfo.verify.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },

                target: () => refs.verify.current,
              },
              gap: [24, 56],
            },
            {
              step: {
                title: stepsInfo.salonSearch.title,
                description: stepsInfo.salonSearch.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.salonSearch.current,
              },
              gap: 8,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                nextButtonProps: isMobile
                  ? {
                      onClick: () => {
                        setOpen(false)
                        navigate("/account/profile/team")
                      },
                    }
                  : undefined,
                target: () => refs.leaderboard.current,
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 4,
            },
          ],
          report: [
            {
              step: {
                title: stepsInfo.report.title,
                description: stepsInfo.report.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.report.current,
              },
              gap: [0, 4],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 12,
            },
          ],
        },
        mobile: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: [0, 24],
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description: stepsInfo.sales.description,
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.addProducts.title,
                description: stepsInfo.addProducts.description,
                target: () => refs.addProducts.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () =>
                    (
                      refs.addProducts.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
              },
              gap: 2,
            },
            {
              step: {
                title: stepsInfo.weeklySales.title,
                description: (
                  <React.Fragment>
                    {"Choose the salon from the dropdown. Next, enter the quantity of sold products per each stylist."}
                    <br />
                    <span>Tip: </span>
                    {
                      "You can edit the number of products sold by returning to this window and choosing the right week and stylist."
                    }
                  </React.Fragment>
                ),
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () =>
                    (
                      refs.closeWeeklySales.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
                nextButtonProps: {
                  onClick: () =>
                    (
                      refs.closeWeeklySales.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
                target: () => refs.weeklySales.current,
              },
              gap: 4,
            },
            {
              step: {
                title: stepsInfo.verify.title,
                description: stepsInfo.verify.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () =>
                    (
                      refs.addProducts.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
                    )?.click(),
                },
                target: () => refs.verify.current,
              },
              gap: [24, 4],
            },
            {
              step: {
                title: stepsInfo.salonSearch.title,
                description: stepsInfo.salonSearch.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.salonSearch.current,
              },
              gap: 8,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                target: () => refs.leaderboard.current,
              },
              gap: [48, 12],
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 12,
            },
          ],
          report: [
            {
              step: {
                title: stepsInfo.report.title,
                description: stepsInfo.report.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.report.current,
              },
              gap: [24, 4],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 12,
            },
          ],
        },
      },
      sales: {
        desktop: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description: stepsInfo.sales.description,
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.addProducts.title,
                description: stepsInfo.addProducts.description,
                target: () => refs.addProducts.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 2,
            },
            {
              step: {
                title: stepsInfo.verify.title,
                description: stepsInfo.verify.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                target: () => refs.verify.current,
              },
              gap: [24, 8],
            },
            {
              step: {
                title: stepsInfo.salonSearch.title,
                description: stepsInfo.salonSearch.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.salonSearch.current,
              },
              gap: 8,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                target: () => refs.leaderboard.current,
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 12,
            },
          ],
          report: [
            {
              step: {
                title: stepsInfo.report.title,
                description: stepsInfo.report.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.report.current,
              },
              gap: [0, 4],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 0,
            },
          ],
        },
        mobile: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: [0, 24],
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description: stepsInfo.sales.description,
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.addProducts.title,
                description: stepsInfo.addProducts.description,
                target: () => refs.addProducts.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 2,
            },
            {
              step: {
                title: stepsInfo.verify.title,
                description: stepsInfo.verify.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                target: () => refs.verify.current,
              },
              gap: [24, 4],
            },
            {
              step: {
                title: stepsInfo.salonSearch.title,
                description: stepsInfo.salonSearch.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.salonSearch.current,
              },
              gap: 8,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                target: () => refs.leaderboard.current,
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 12,
            },
          ],
          report: [
            {
              step: {
                title: stepsInfo.report.title,
                description: stepsInfo.report.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.report.current,
              },
              gap: [24, 4],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 0,
            },
          ],
        },
      },
      distributor: {
        desktop: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description: stepsInfo.sales.description,
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.addProducts.title,
                description: stepsInfo.addProducts.description,
                target: () => refs.addProducts.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 2,
            },
            {
              step: {
                title: stepsInfo.verify.title,
                description: stepsInfo.verify.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                target: () => refs.verify.current,
              },
              gap: [24, 8],
            },
            {
              step: {
                title: stepsInfo.salonSearch.title,
                description: stepsInfo.salonSearch.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.salonSearch.current,
              },
              gap: 8,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                target: () => refs.leaderboard.current,
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 12,
            },
          ],
          report: [
            {
              step: {
                title: stepsInfo.report.title,
                description: stepsInfo.report.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.report.current,
              },
              gap: [0, 4],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 0,
            },
          ],
        },
        mobile: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: [0, 24],
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description: stepsInfo.sales.description,
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.addProducts.title,
                description: stepsInfo.addProducts.description,
                target: () => refs.addProducts.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 2,
            },
            {
              step: {
                title: stepsInfo.verify.title,
                description: stepsInfo.verify.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                target: () => refs.verify.current,
              },
              gap: [24, 4],
            },
            {
              step: {
                title: stepsInfo.salonSearch.title,
                description: stepsInfo.salonSearch.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.salonSearch.current,
              },
              gap: 8,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                target: () => refs.leaderboard.current,
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 12,
            },
          ],
          report: [
            {
              step: {
                title: stepsInfo.report.title,
                description: stepsInfo.report.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.report.current,
              },
              gap: [24, 4],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 0,
            },
          ],
        },
      },
      region: {
        desktop: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description: stepsInfo.sales.description,
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 24,
            },
            {
              step: {
                title: stepsInfo.verify.title,
                description: stepsInfo.verify.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                target: () => refs.verify.current,
              },
              gap: [24, 8],
            },
            {
              step: {
                title: stepsInfo.salonSearch.title,
                description: stepsInfo.salonSearch.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.salonSearch.current,
              },
              gap: 8,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                target: () => refs.leaderboard.current,
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 12,
            },
          ],
          report: [
            {
              step: {
                title: stepsInfo.report.title,
                description: stepsInfo.report.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.report.current,
              },
              gap: [0, 4],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 0,
            },
          ],
        },
        mobile: {
          homepage: [
            {
              step: {
                title: stepsInfo.results.title,
                description: stepsInfo.results.description,
                target: () => refs.results.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: [0, 24],
            },
            {
              step: {
                title: stepsInfo.sales.title,
                description: stepsInfo.sales.description,
                target: () => refs.sales.current,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.verify.title,
                description: stepsInfo.verify.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                target: () => refs.verify.current,
              },
              gap: [24, 4],
            },
            {
              step: {
                title: stepsInfo.salonSearch.title,
                description: stepsInfo.salonSearch.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                target: () => refs.salonSearch.current,
              },
              gap: 8,
            },
          ],
          leaderboard: [
            {
              step: {
                title: stepsInfo.leaderboard.title,
                description: stepsInfo.leaderboard.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/homepage")
                  },
                },
                target: () => refs.leaderboard.current,
              },
              gap: 12,
            },
            {
              step: {
                title: stepsInfo.filters.title,
                description: stepsInfo.filters.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.filters.current,
              },
              gap: 12,
            },
          ],
          report: [
            {
              step: {
                title: stepsInfo.report.title,
                description: stepsInfo.report.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/leaderboard")
                  },
                },
                nextButtonProps: {
                  onClick: () => {
                    navigate("/account/how-it-works/about")
                  },
                },
                target: () => refs.report.current,
              },
              gap: [24, 4],
            },
          ],
          howItWorks: [
            {
              step: {
                title: stepsInfo.howItWorks.title,
                description: stepsInfo.howItWorks.description,
                prevButtonProps: {
                  ...prevButtonDefault,
                  onClick: () => {
                    navigate("/account/report")
                  },
                },
                target: () => refs.howItWorks.current,
              },
              gap: 0,
            },
          ],
        },
      },
    }),
    [isMobile, navigate, prevButtonDefault, refs]
  )

  const getNewTourProps = useCallback((): TourProps => {
    const tourProps: TourProps = { steps: undefined, gap: { offset: 4 } }

    if (!role) {
      return tourProps
    }

    const deviceSteps = steps[role]?.[isMobile ? "mobile" : "desktop"]
    const allSteps = Object.values(deviceSteps ?? {}).flat()

    return {
      gap: { offset: allSteps[currentStep].gap },
      steps: allSteps?.map(it => it.step),
    }
  }, [currentStep, isMobile, role, steps])

  const tourStepsProps = getNewTourProps()

  useEffect(() => {
    // start tour
    if (currentStep === 0) {
      if (user?.id && !user.guided) {
        localStorage.removeItem("guided")
        navigate("/account/homepage")
        setOpen(true)
      } else {
        localStorage.setItem("guided", "true")
        setOpen(false)
      }
    }
  }, [currentStep, navigate, user?.guided, user?.id])

  useEffect(() => {
    if (!localStorage.getItem("guided") && currentStep > 0) {
      setOpen(false)
      setTimeout(() => {
        setOpen(true)
      }, 200)
    }
  }, [pathname])

  const onClose = async () => {
    if (user?.id) {
      await updateUser({
        variables: {
          id: user?.id,
          data: {
            guided: true,
          },
        },
      })

      await refetchUser()
      localStorage.setItem("guided", "true")
      setOpen(false)
      navigate("/account/homepage")
    }
  }

  return (
    <TourContext.Provider
      value={{
        open,
        setOpen,
        currentStep,
        setCurrentStep,
        refs,
      }}
    >
      <Tour
        {...tourStepsProps}
        closeIcon={<CloseIcon />}
        open={open && !isOffline}
        onClose={onClose}
        onFinish={onClose}
        current={currentStep}
        onChange={step => setCurrentStep(step)}
        indicatorsRender={(curr, total) => (
          <span>
            {curr + 1} of {total}
          </span>
        )}
        rootClassName={"guided-tour"}
      />
      {children}
    </TourContext.Provider>
  )
}
