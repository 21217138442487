import { initMarker } from "@bn-digital/sdk"
import BugsnagPerformance from "@bugsnag/browser-performance"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import React from "react"
import { createRoot } from "react-dom/client"
import App from "./components/app"

Promise.resolve(!!import.meta.env.WEBSITE_MARKER_ID).then(enabled =>
  initMarker({ enabled, destination: import.meta.env.WEBSITE_MARKER_ID })
)

const rootElement = document.querySelector("#root") as HTMLElement

if (!import.meta.env.DEV) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_APIKEY,
    plugins: [new BugsnagPluginReact()],
    onError: event => {
      const user = localStorage.getItem("bugsnagUserInfo")

      if (user) {
        try {
          const { id, email, name } = JSON.parse(user)

          if (id && email && name) {
            event.setUser(id, email, name)
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
  })

  BugsnagPerformance.start({
    apiKey: import.meta.env.VITE_BUGSNAG_APIKEY,
    enabledReleaseStages: ["staging", "production"],
    releaseStage: import.meta.env.BASE_URL.includes("bndigital.dev") ? "staging" : "production",
  })

  const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React)

  const ErrorView = () => (
    <div style={{ display: "flex", height: "100vh", alignItems: "center", justifyContent: "center" }}>
      <p>Something went wrong</p>
      <br />
      <p>Try to reload this page</p>
    </div>
  )

  createRoot(rootElement).render(
    ErrorBoundary ? (
      <ErrorBoundary FallbackComponent={ErrorView}>
        <App />
      </ErrorBoundary>
    ) : (
      <App />
    )
  )
} else {
  createRoot(rootElement).render(<App />)
}
