import React, { FC } from "react"
import { Helmet } from "react-helmet"

import { ReactComponent as Reload } from "src/icons/reload.svg"
import { ReactComponent as NoConnectionImage } from "src/images/no-connection.svg"

import "./index.less"

type NoConnectionProps = {
  handleRecheckConnection: () => void
}

const NoConnection: FC<NoConnectionProps> = ({ handleRecheckConnection }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>KEVIN.MURPHY Tournament</title>
      </Helmet>

      <section className={"page-404"}>
        <div className={"image-wrapper"}>
          <NoConnectionImage />
        </div>

        <div className={"description-wrapper"}>
          <h1>No Connection</h1>
          <h2>Seems like your connection is off...</h2>
          <h2>Click button bellow to try again</h2>
          <div className={"button-wrapper"}>
            <button onClick={handleRecheckConnection}>
              Try Again
              <Reload />
            </button>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default NoConnection
