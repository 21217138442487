import { Button, Layout } from "antd"
import clsx from "clsx"
import { FC } from "react"
import { useLocation, useNavigate } from "react-router"

import { useApp } from "src/components/app"
import { useAuth } from "src/components/app/Auth"
import { NewTooltipWrapper } from "src/components/UI/NewToolTipWrapper"

import { useGetSalonQuery } from "src/graphql"

import logo from "src/icons/logo.png"

import { ReactComponent as Logout } from "src/icons/logout.svg"

import { useBreakpoints } from "src/hooks/useBreakpoints"
import { baseItems, getSalonItems, otherLevelItems } from "../navigation-items"
import "./index.less"

// TODO: skeleton when loading

export const AccountSidebar: FC<{ isLogo?: boolean; isPageReady?: boolean }> = () => {
  const { isMobile } = useBreakpoints()

  const {
    app: { userType, userTypeId },
  } = useApp()

  const { user, logout } = useAuth()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { data: salon } = useGetSalonQuery({ variables: { id: userTypeId?.toString() ?? "" } })

  const avatar = salon?.salon?.data?.attributes?.avatar?.data[0]?.attributes?.url

  if (!user) {
    return null
  }

  return (
    <Layout.Sider className={"account-sidebar"} width={129}>
      <div className={`logo-wrapper`}>
        <img src={logo} alt={"Kevin Murphy Logo"} />
      </div>

      <nav className={"navbar"}>
        {baseItems.concat(userType === "salon" ? getSalonItems(avatar, isMobile) : otherLevelItems).map(item => (
          <NewTooltipWrapper key={item.link} content={item.tooltipContent}>
            <Button
              ghost
              className={clsx("nav-item", { active: pathname.includes(item.link) }, item.className)}
              onClick={() => navigate(item.link)}
            >
              {item.icon}
            </Button>
          </NewTooltipWrapper>
        ))}
      </nav>

      <NewTooltipWrapper content={<span>Logout</span>}>
        <Button ghost className={"logout-btn"} onClick={logout}>
          <Logout />
        </Button>
      </NewTooltipWrapper>
    </Layout.Sider>
  )
}
