import { TourProps, TourStepProps } from "antd"
import React from "react"

export type RefUnionType =
  | "results"
  | "sales"
  | "addProducts"
  | "weeklySales"
  | "closeWeeklySales"
  | "leaderboard"
  | "filters"
  | "team"
  | "howItWorks"
  | "verify"
  | "salonSearch"
  | "report"

export type UserRoleType = "salon" | "dsc" | "sales" | "distributor" | "region"

export type PageType = "leaderboard" | "homepage" | "team" | "report" | "howItWorks"

export type StepsType = Partial<
  Record<
    UserRoleType,
    Record<"mobile" | "desktop", Partial<Record<PageType, { step: TourStepProps; gap: number | [number, number] }[]>>>
  >
>

export const getRoleName = (role: string | undefined): UserRoleType | undefined => {
  switch (role) {
    case "Salon":
      return "salon"
    case "DSC":
      return "dsc"
    case "Sales Manager":
      return "sales"
    case "Distributor":
      return "distributor"
    case "Region":
      return "region"
    default:
      return
  }
}

export const stepsInfo: Record<
  Exclude<RefUnionType, "closeWeeklySales">,
  { title: React.ReactNode; description: React.ReactNode }
> = {
  results: {
    title: "Results Comparison",
    description:
      "View a graph comparing this year's results with last year's. If there was no participation last year, only the current data will be shown.",
  },
  sales: {
    title: "Weekly Sales Breakdown",
    description:
      "View the total products sold this week compared to last week, along with a breakdown by Full-size and Mini-size products. You can also see the average number of entries per salon this week and a comparison of entries from this week versus all previous weeks.",
  },
  addProducts: {
    title: "Add Products",
    description:
      'Click "Add products" to enter sales for each stylist. Use "Unregistered Stylist" for non-participating stylists.',
  },
  weeklySales: {
    title: "Enter Weekly Sales",
    description: (
      <React.Fragment>
        {"Choose the week from the dropdown. Next, enter the quanity of sold products per each stylist."}
        <br />
        <span>Tip: </span>
        {
          "You can edit the number of products sold by returning to this window and choosing the right week and stylist."
        }
      </React.Fragment>
    ),
  },
  leaderboard: {
    title: "Leaderboards",
    description:
      "The Leaderboards display the results for the 2024 Tournament, with three tabs available: Individual Stylist, salon Total, and salon Average.",
  },
  filters: {
    title: "Filtering Options",
    description:
      "You can filter the table by typing in the Distributor or Country field, or by selecting a Salon Size from the dropdown list. Choose specific options from the dropdowns to refine your search.",
  },
  team: {
    title: "Profile > Team",
    description: (
      <React.Fragment>
        {"Add or remove stylists under the Team section."}
        <br />
        <span>Note: </span>
        {
          "If you remove stylists during a competition, their points will be removed from the stylist leaderboard but will be counted in your salon's leaderboard."
        }
      </React.Fragment>
    ),
  },
  howItWorks: {
    title: "How It Works",
    description:
      'Learn about the KM Tournament, its categories, prizes, and deadlines. To replay the guided tour, click "Start guided tour" on the About page.',
  },
  verify: {
    title: "Verify Points",
    description:
      "Toggle to verify points for products. You can click the plus icon to expand Salons and see individual Stylists to verify them separately.",
  },
  salonSearch: {
    title: "Salon Search",
    description:
      "You can find the right salon by clicking the 'Search' button. Enter the salon name or keywords, and the list will be filtered to show only matching options. After that, you will be able to verify the points.",
  },
  report: {
    title: "Report",
    description:
      'Here, you can filter points by team roles and  "Verified Only" points. Select weeks from the dropdown menu, and download the report as a CSV file.',
  },
}
