import { FC, Suspense, useEffect } from "react"
import { Outlet } from "react-router-dom"

import { LoadingPage } from "src/pages/LoadingPage"
import NoConnection from "src/pages/NoConnection"
import { useApp } from "../app"

export const BaseLayout: FC = () => {
  const {
    offline: { isOffline, setIsOffline },
  } = useApp()

  const handleOfflineEvent = () => {
    setTimeout(() => {
      !navigator.onLine && setIsOffline(true)
    }, 3000)
  }

  const handleRecheckConnection = () => {
    if (navigator.onLine) {
      setIsOffline(false)
    }
  }

  useEffect(() => {
    window.addEventListener("offline", handleOfflineEvent)

    return () => {
      window.removeEventListener("offline", handleOfflineEvent)
    }
  }, [])

  return (
    <Suspense fallback={<LoadingPage />}>
      {!isOffline ? <Outlet /> : <NoConnection handleRecheckConnection={handleRecheckConnection} />}
    </Suspense>
  )
}
