import { ThemeConfig } from "antd/es/config-provider"

const COLORS = {
  white: "#FFFFFF",
  dark: "#1C1C23",
  asphalt: "#677685",
}

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
    },
    Input: {
      borderRadius: 0,
    },
    Button: {
      // default
      defaultShadow: "unset",
      colorBgContainer: COLORS.dark,
      colorText: COLORS.white,
      borderRadius: 0,
      textTextColor: COLORS.dark,
      // hover
      defaultHoverBg: COLORS.asphalt,
      colorPrimaryHover: COLORS.white,
      colorPrimaryTextHover: COLORS.white,
      textTextHoverColor: COLORS.white,
      defaultHoverBorderColor: "unset",
      // active
      defaultActiveBorderColor: "unset",
      defaultActiveColor: COLORS.white,
      colorPrimaryTextActive: COLORS.white,
      // disabled
      colorTextDisabled: COLORS.white,
      colorBgContainerDisabled: "rgba(164, 169, 184, 0.60)",
    },
  },
}

export default theme
