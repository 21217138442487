import { Tooltip, TooltipProps } from "antd"
import { FC, PropsWithChildren, ReactNode } from "react"

import "./index.less"

interface NewTooltipWrapperProps {
  content: ReactNode
  placement?: TooltipProps["placement"]
  minWidth?: string
}

export const NewTooltipWrapper: FC<PropsWithChildren<NewTooltipWrapperProps>> = ({
  children,
  content,
  placement = "left",
  minWidth,
}) => {
  return (
    <Tooltip
      rootClassName={"tooltip-wrapper"}
      overlayInnerStyle={minWidth ? { minWidth } : undefined}
      title={content}
      placement={placement}
    >
      {children}
    </Tooltip>
  )
}
