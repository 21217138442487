import noLogo from "src/icons/logo-avatar.png"

import { ReactComponent as Clock } from "src/icons/clock.svg"
import { ReactComponent as Download } from "src/icons/download.svg"
import { ReactComponent as Home } from "src/icons/home.svg"
import { ReactComponent as Info } from "src/icons/info.svg"
import { ReactComponent as Leaderboard } from "src/icons/leaderboard.svg"
import { ReactComponent as News } from "src/icons/news.svg"
import { ReactComponent as Play } from "src/icons/play.svg"
import { ReactComponent as Profile } from "src/icons/profile.svg"
import { ReactComponent as Ranking } from "src/icons/ranking.svg"

type NavigationItemType = {
  icon: JSX.Element
  link: string
  tooltipContent: JSX.Element
  className?: string
}

export const baseItems: NavigationItemType[] = [
  {
    icon: <Home />,
    link: "/account/homepage",
    tooltipContent: <span>Homepage</span>,
  },
  {
    icon: <Leaderboard />,
    link: "/account/leaderboard",
    tooltipContent: <span>Leaderboard</span>,
  },
  {
    icon: <Info />,
    link: "/account/how-it-works",
    tooltipContent: <span>How it works</span>,
  },
  {
    icon: <Play />,
    link: "/account/library",
    tooltipContent: <span>Video library</span>,
  },
  {
    icon: <News />,
    link: "/account/news",
    tooltipContent: <span>Brand updates</span>,
  },
]

export const getSalonItems = (avatar: string | undefined, isMobile: boolean): NavigationItemType[] => {
  return [
    {
      icon: isMobile ? <Profile /> : <img src={avatar ?? noLogo} alt={"User Avatar"} />,
      link: "/account/profile/account",
      tooltipContent: <span>Profile</span>,
      className: "profile-img",
    },
  ]
}

export const otherLevelItems: NavigationItemType[] = [
  {
    icon: <Download />,
    link: "/account/report",
    tooltipContent: <span>Report</span>,
  },
  {
    icon: <Ranking />,
    link: "/account/dsc-ranking",
    tooltipContent: <span>DSC ranking report</span>,
  },
  {
    icon: <Clock />,
    link: "/account/activity",
    tooltipContent: <span>Activity log</span>,
  },
]
