import { Button, Drawer } from "antd"
import clsx from "clsx"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { useGetSalonQuery } from "src/graphql"

import noLogo from "src/icons/logo-avatar.png"
import logo from "src/icons/logo.png"

import { ReactComponent as Logout } from "src/icons/logout.svg"
import { ReactComponent as MenuIcon } from "src/icons/menu_alt.svg"

import { useApp } from "src/components/app"
import { useAuth } from "src/components/app/Auth"

import { useBreakpoints } from "src/hooks/useBreakpoints"

import { baseItems, getSalonItems, otherLevelItems } from "../navigation-items"

import "./index.less"

export const AccountHeader: React.FC = () => {
  const { isMobile } = useBreakpoints()

  const {
    app: { isPageReady, userTypeId, userType },
  } = useApp()

  const { logout } = useAuth()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { data: salon } = useGetSalonQuery({ variables: { id: userTypeId?.toString() ?? "" } })

  const [open, setOpen] = useState(false)

  const avatar = salon?.salon?.data?.attributes?.avatar?.data[0]?.attributes?.url

  return (
    <div className={"account-header"}>
      <Button ghost className={"toggle-navigation-btn"} onClick={() => setOpen(true)}>
        <MenuIcon />
      </Button>

      <div className={"right-side-block"}>
        {userType == "salon" && (
          <div className={clsx("profile-img", { skeleton: !isPageReady && userType == "salon" })}>
            <img src={avatar ?? noLogo} alt={"User Avatar"} />
          </div>
        )}

        <Button ghost className={"logout-btn"} onClick={logout}>
          <Logout />
        </Button>
      </div>

      <Drawer
        rootClassName={"navigation-drawer"}
        open={open}
        onClose={() => setOpen(false)}
        title={<img src={logo} alt={"logo"} className={"logo"} />}
        width={"100%"}
        placement={"right"}
      >
        <div className={"navbar"}>
          {baseItems.concat(userType === "salon" ? getSalonItems(avatar, isMobile) : otherLevelItems).map(item => (
            <Button
              key={item.link}
              ghost
              className={clsx("nav-item", { active: pathname.includes(item.link) }, item.className)}
              onClick={() => {
                navigate(item.link)
                setOpen(false)
              }}
            >
              {item.icon}
              {item.tooltipContent}
            </Button>
          ))}
        </div>
      </Drawer>
    </div>
  )
}
