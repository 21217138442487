import { Layout } from "antd"
import React, { Suspense } from "react"

import { TourProvider } from "src/components/Tour"

import { useBreakpoints } from "src/hooks/useBreakpoints"

import { LoadingPage } from "src/pages/LoadingPage"

import { BaseLayout } from "../BaseLayout"
import { AccountHeader } from "./AccountHeader"
import { AccountSidebar } from "./AccountSidebar"

export const AccountLayout: React.FC = () => {
  const { isMobile } = useBreakpoints()

  return (
    <Suspense fallback={<LoadingPage />}>
      <TourProvider>
        <Layout className={"account-layout"}>
          {isMobile ? <AccountHeader /> : <AccountSidebar />}

          <Layout.Content>
            <BaseLayout />
          </Layout.Content>
        </Layout>
      </TourProvider>
    </Suspense>
  )
}
